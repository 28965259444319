html,
body {
  height: 100%;
  scroll-behavior: smooth;
}
body {
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  max-width: 1920px;
}

.content-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.component {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: 'Roboto';
  max-width: 1440px;
  margin: 0 auto;
  padding-bottom: 50px;

  h2 {
    text-align: center;
    font-weight: bold;
    font-size: 70px;
    margin-top: 0;

    @media screen and (max-width: 1680px) {
      font-size: 58px;
    }
    @media screen and (max-width: 1440px) {
      font-size: 48px;
    }
    @media screen and (max-width: 1280px) {
      font-size: 42px;
    }
    @media screen and (max-width: 650px) {
      font-size: 38px;
    }
    @media screen and (max-width: 320px) {
      font-size: 32px;
    }
  }
  .component-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .component-info {
      .box {
        &:last-child {
          margin-top: 50px;

          @media screen and (max-width: 650px) {
            margin-top: 30px;
            margin-bottom: 30px;
          }
        }

        h4 {
          font-weight: bold;
          font-size: 42px;
          margin: 0;

          @media screen and (max-width: 1680px) {
            font-size: 30px;
          }
          @media screen and (max-width: 1440px) {
            font-size: 26px;
          }
          @media screen and (max-width: 1280px) {
            font-size: 24px;
          }
          @media screen and (max-width: 320px) {
            font-size: 22px;
          }
        }
        p {
          font-size: 20px;

          @media screen and (max-width: 1680px) {
            font-size: 18px;
          }
          @media screen and (max-width: 1440px) {
            font-size: 16px;
          }
          @media screen and (max-width: 1280px) {
            font-size: 14px;
          }
        }
      }
    }

    .component-img {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      img {
        width: 100%;
      }

      @media screen and (max-width: 650px) {
        width: 80%;
      }
    }
    @media screen and (max-width: 1440px) {
      padding: 40px 50px;
    }
    @media screen and (max-width: 1366px) {
      padding: 40px 50px 0 50px;
    }
    @media screen and (max-width: 650px) {
      flex-direction: column;
      padding: 20px 30px 0 30px;
    }
    @media screen and (max-width: 375px) {
      padding: 20px 20px 0 20px;
    }
  }
  @media screen and (max-width: 1680px) {
    max-width: 1280px;
  }
  @media screen and (max-width: 1600px) {
    max-width: 1200px;
  }
  @media screen and (max-width: 1440px) {
    background-size: 40%;
  }
  @media screen and (max-width: 1366px) {
    max-width: 1120px;
  }
  @media screen and (max-width: 1280px) {
    max-width: 1100px;
  }
  @media screen and (max-width: 1024px) {
    max-width: 965px;
  }
  @media screen and (max-width: 650px) {
    padding-bottom: 20px;
  }
}
.offer {
  background-image: url('./assets/billog-oferts.png');
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;

  h2 {
    margin-bottom: 35px;

    @media screen and (max-width: 1440px) {
      margin-bottom: 20px;
    }
    @media screen and (max-width: 320px) {
      margin-bottom: 0;
    }
  }
  .component-box {
    .component-info {
      width: 50%;

      @media screen and (max-width: 1024px) {
        width: 55%;
      }
      @media screen and (max-width: 650px) {
        width: 100%;
      }
    }
    .mobile-img {
      display: none;

      img {
        height: 50%;
        width: 100%;
      }

      @media screen and (max-width: 650px) {
        display: inherit;
        width: 75%;
      }
    }
  }
  @media screen and (max-width: 1440px) {
    background-size: 36%;
  }
  @media screen and (max-width: 1366px) {
    background-size: 34%;
  }
  @media screen and (max-width: 1280px) {
    background-size: 30%;
  }
  @media screen and (max-width: 768px) {
    background-position: center right;
  }
  @media screen and (max-width: 650px) {
    background-image: none;
  }
}

.team {
  h2 {
    @media screen and (max-width: 1440px) {
      margin: 0;
    }
  }
  .component-box {
    .component-info {
      width: 40%;

      @media screen and (max-width: 1024px) {
        width: 45%;
      }
      @media screen and (max-width: 650px) {
        width: 100%;
      }
    }
  }
}
.hamburger {
  display: none;

  .line {
    width: 30px;
    height: 2px;
    background-color: #a872b9;
    display: block;
    margin: 8px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    @media screen and (max-width: 650px) {
      width: 24px;
    }
  }

  @media screen and (max-width: 768px) {
    display: block;
    position: relative;
  }
}
#team,
#offer {
  margin-top: -80px;
  padding-top: 80px;
}
