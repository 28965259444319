.main-component {
  .nav-bar {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0.8;
    width: 100%;
    display: flex;
    flex-direction: column;

    .nav-content {
      padding: 21px 170px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background: #ffffff 0% 0% no-repeat padding-box;

      .nav-logo {
        h1 {
          color: #a872b9;
          font-family: 'Fredoka One', cursive;
          font-size: 56px;
          margin: 0;

          @media screen and (max-width: 1440px) {
            font-size: 50px;
          }
          @media screen and (max-width: 1280px) {
            font-size: 42px;
          }
        }
      }
      nav {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 380px;

        .nav {
          font-size: 20px;
          color: #707070;
          font-weight: bold;
          cursor: pointer;
          text-decoration: none;

          &::after {
            content: '';
            display: block;
            width: 0;
            height: 2px;
            background: #000000;
            -webkit-transition: width 0.3s;
            -o-transition: width 0.3s;
            transition: width 0.3s;
          }

          &:hover::after {
            width: 100%;
          }
          &:hover {
            color: #000000;
            transition: all 0.3s;
          }
          @media screen and (max-width: 1680px) {
            font-size: 18px;
          }
          @media screen and (max-width: 1440px) {
            font-size: 16px;
          }
          @media screen and (max-width: 1280px) {
            font-size: 15px;
          }
        }
        @media screen and (max-width: 1440px) {
          width: 340px;
        }
        @media screen and (max-width: 1280px) {
          width: 320px;
        }
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
      @media screen and (max-width: 1680px) {
        padding: 10px 130px;
      }
      @media screen and (max-width: 1440px) {
        padding: 5px 110px;
      }
      @media screen and (max-width: 1280px) {
        padding: 5px 90px;
      }
      @media screen and (max-width: 1024px) {
        padding: 5px 30px;
      }
      @media screen and (max-width: 650px) {
        padding: 5px 20px;
      }
    }
    .floating-icons {
      padding-right: 100px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;

      .icon {
        margin: 10px 0;
        width: 44px;
        height: 44px;
        background: #f2f3f7 0% 0% no-repeat padding-box;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 20px;
          height: 20px;

          @media screen and (max-width: 1680px) {
            width: 16px;
            height: 16px;
          }
          @media screen and (max-width: 1440px) {
            width: 14px;
            height: 14px;
          }
        }
        @media screen and (max-width: 1680px) {
          width: 38px;
          height: 38px;
        }
        @media screen and (max-width: 1440px) {
          width: 34px;
          height: 34px;
        }
      }
      @media screen and (max-width: 1680px) {
        padding-right: 50px;
      }
      @media screen and (max-width: 1024px) {
        padding-right: 30px;
      }
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
  .welcome-component {
    height: 100vh;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    background-image: url('assets/background-billog.png');
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 75%;

    .welcome-info {
      h1 {
        font-family: 'Fredoka One';
        font-size: 92px;
        margin: 0;
        font-weight: 500;

        @media screen and (max-width: 1680px) {
          font-size: 80px;
        }
        @media screen and (max-width: 1440px) {
          font-size: 70px;
        }
        @media screen and (max-width: 1280px) {
          font-size: 60px;
        }
        @media screen and (max-width: 768px) {
          font-size: 70px;
        }
        @media screen and (max-width: 768px) {
          font-size: 55px;
        }
        @media screen and (max-width: 320px) {
          font-size: 46px;
        }
      }
      p {
        width: 55%;
        font-size: 26px;
        font-family: 'Roboto';

        @media screen and (max-width: 1680px) {
          font-size: 22px;
        }
        @media screen and (max-width: 1440px) {
          font-size: 20px;
        }
        @media screen and (max-width: 1280px) {
          font-size: 16px;
        }
        @media screen and (max-width: 768px) {
          font-size: 20px;
          margin: 0 auto;
        }
        @media screen and (max-width: 650px) {
          font-size: 18px;
          width: 80%;
          margin-top: 10px;
        }
        @media screen and (max-width: 320px) {
          font-size: 16px;
          width: 90%;
        }
      }
      @media screen and (max-width: 1440px) {
        margin-left: 60px;
      }
      @media screen and (max-width: 1366px) {
        margin-left: 95px;
      }
      @media screen and (max-width: 1280px) {
        margin-left: 60px;
      }
      @media screen and (max-width: 768px) {
        margin-left: 0;
        padding-top: 100px;
        text-align: center;
      }
      @media screen and (max-width: 320px) {
        padding-top: 80px;
      }
    }
    @media screen and (max-width: 1680px) {
      max-width: 1280px;
    }
    @media screen and (max-width: 1440px) {
      background-size: 70%;
      max-width: 1220px;
    }
    @media screen and (max-width: 1366px) {
      background-size: 60%;
    }
    @media screen and (max-width: 1280px) {
      max-width: 1100px;
      background-size: 70%;
    }
    @media screen and (max-width: 1024px) {
      max-width: 1000px;
      background-size: 66%;
      height: 600px;
    }
    @media screen and (max-width: 768px) {
      background-position: bottom center;
      justify-content: center;
      align-items: flex-start;
      background-size: 75%;
      height: 640px;
    }
    @media screen and (max-width: 650px) {
      background-size: 95%;
      height: 470px;
      background-position-x: -20px;
      background-position-y: 192px;
    }
    @media screen and (max-width: 360px) {
      height: 460px;
    }
  }
}
.contact-component {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 1170px;
  margin: 0 auto;
  padding-bottom: 50px;

  h2 {
    text-align: center;
    font-weight: bold;
    font-size: 70px;

    @media screen and (max-width: 1680px) {
      font-size: 58px;
    }
    @media screen and (max-width: 1440px) {
      font-size: 48px;
    }
    @media screen and (max-width: 1366px) {
      font-size: 46px;
    }
    @media screen and (max-width: 1280px) {
      font-size: 40px;
    }
    @media screen and (max-width: 1024px) {
      font-size: 36px;
    }
    @media screen and (max-width: 650px) {
      font-size: 30px;
    }
    @media screen and (max-width: 320px) {
      font-size: 28px;
    }
  }
  .contact-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .card {
      width: 470px;
      height: 220px;
      border: 3px solid #70707080;
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .card-logo {
        color: #a872b9;
        font-family: 'Fredoka One';
        font-size: 20px;
        padding: 20px 0 0 15px;

        @media screen and (max-width: 1366px) {
          padding: 15px 0 0 10px;
        }
        @media screen and (max-width: 1280px) {
          padding: 10px 0 0 10px;
          font-size: 18px;
        }
      }
      .card-contact {
        text-align: right;
        padding: 0 20px 15px 0;
        font-family: 'Roboto';
        font-weight: bold;
        font-size: 18px;

        span {
          color: #a872b9;
        }

        @media screen and (max-width: 1680px) {
          font-size: 16px;
        }
        @media screen and (max-width: 1366px) {
          padding: 0 15px 10px 0;
          font-size: 14px;
        }
        @media screen and (max-width: 1280px) {
          padding: 0 10px 8px 0;
          font-size: 13px;
        }
      }
      @media screen and (max-width: 1600px) {
        width: 420px;
        height: 180px;
      }
      @media screen and (max-width: 1366px) {
        width: 400px;
        height: 160px;
      }
      @media screen and (max-width: 1280px) {
        width: 360px;
        height: 140px;
      }
      @media screen and (max-width: 1024px) {
        border: 2px solid #70707080;
        width: 320px;
        height: 140px;
      }
      @media screen and (max-width: 320px) {
        width: 90%;
      }
    }
    .contact-text {
      font-family: 'Roboto';
      font-weight: bold;
      font-size: 18px;

      @media screen and (max-width: 1680px) {
        font-size: 16px;
      }
      @media screen and (max-width: 1366px) {
        font-size: 15px;
      }
      @media screen and (max-width: 1280px) {
        font-size: 14px;
      }
    }
    .contact-items {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 40%;

      .contact-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: #000000;

        .contact-icon {
          box-shadow: 5px 2px 10px #dde4ef;
          border-radius: 10px;
          width: 95px;
          height: 95px;
          background: #f2f3f7 0% 0% no-repeat padding-box;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.4s;

          img {
            width: 36px;
            height: 36px;

            @media screen and (max-width: 1366px) {
              width: 32px;
              height: 32px;
            }
            @media screen and (max-width: 1280px) {
              width: 28px;
              height: 28px;
            }
            @media screen and (max-width: 1024px) {
              width: 24px;
              height: 24px;
            }
          }
          @media screen and (max-width: 1366px) {
            width: 88px;
            height: 88px;
          }
          @media screen and (max-width: 1280px) {
            width: 82px;
            height: 82px;
          }
          @media screen and (max-width: 1024px) {
            width: 76px;
            height: 76px;
          }
          @media screen and (max-width: 650px) {
            width: 70px;
            height: 70px;
          }
          &:hover {
            transition: all 0.4s;
            box-shadow: 8px 8px 12px #a872b9;
          }
        }
      }
      @media screen and (max-width: 1366px) {
        width: 36%;
      }
      @media screen and (max-width: 1280px) {
        width: 34%;
      }
      @media screen and (max-width: 768px) {
        width: 40%;
      }
      @media screen and (max-width: 650px) {
        width: 65%;
      }
      @media screen and (max-width: 375px) {
        width: 70%;
      }
      @media screen and (max-width: 320px) {
        width: 82%;
      }
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
      height: 300px;
    }
  }
  @media screen and (max-width: 1600px) {
    max-width: 1000px;
  }
  @media screen and (max-width: 1280px) {
    max-width: 900px;
  }
  @media screen and (max-width: 1024px) {
    max-width: 800px;
  }
}
.facebook {
  &::after {
    content: '';
    width: 0;
    height: 44px;
    position: absolute;
    display: inline-block;
    -webkit-transition: width 0.3s;
    -o-transition: width 0.3s;
    transition: width 0.3s;

    @media screen and (max-width: 1680px) {
      height: 38px;
    }
    @media screen and (max-width: 1440px) {
      height: 34px;
    }
  }
  &:hover::after {
    background: #f2f3f7 0% 0% no-repeat padding-box;
    border-radius: 10px;
    content: 'Portfolio';
    width: 100px;
    padding-left: 20px;
    right: 100px;
    display: flex;
    align-items: center;
    overflow: hidden;
    @media screen and (max-width: 1680px) {
      right: 50px;
    }
    @media screen and (max-width: 1024px) {
      right: 30px;
    }
  }
  &:hover {
    font-weight: bold;
    color: #000000;
    transition: all 0.3s;
  }
}
.mail {
  &::after {
    content: '';
    width: 0;
    height: 44px;
    position: absolute;
    display: inline-block;
    -webkit-transition: width 0.3s;
    -o-transition: width 0.3s;
    transition: width 0.3s;

    @media screen and (max-width: 1680px) {
      height: 38px;
    }
    @media screen and (max-width: 1440px) {
      height: 34px;
    }
  }
  &:hover::after {
    background: #f2f3f7 0% 0% no-repeat padding-box;
    border-radius: 10px;
    content: 'office@billogstudio.com';
    width: 210px;
    padding-left: 20px;
    right: 100px;
    display: flex;
    align-items: center;
    overflow: hidden;
    @media screen and (max-width: 1680px) {
      right: 50px;
    }
    @media screen and (max-width: 1024px) {
      right: 30px;
    }
  }
  &:hover {
    font-weight: bold;
    color: #000000;
    transition: all 0.3s;
  }
}
.linkedin {
  &::after {
    content: '';
    width: 0;
    height: 44px;
    position: absolute;
    display: inline-block;
    -webkit-transition: width 0.3s;
    -o-transition: width 0.3s;
    transition: width 0.3s;

    @media screen and (max-width: 1680px) {
      height: 38px;
    }
    @media screen and (max-width: 1440px) {
      height: 34px;
    }
  }
  &:hover::after {
    background: #f2f3f7 0% 0% no-repeat padding-box;
    border-radius: 10px;
    content: 'linkedin.com/company/billogstudio';
    width: 290px;
    padding-left: 20px;
    right: 100px;
    display: flex;
    white-space: nowrap;
    align-items: center;
    overflow: hidden;

    @media screen and (max-width: 1680px) {
      right: 50px;
    }
    @media screen and (max-width: 1024px) {
      right: 30px;
    }
  }
  &:hover {
    font-weight: bold;
    color: #000000;
    transition: all 0.3s;
  }
}
.offer-categories {
  height: 300px;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 50px;

  .categories-box {
    width: 50%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;

    .categories-space {
      width: 50%;
      display: flex;
      justify-content: center;

      .categories-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 240px;
        height: 200px;
        background: #f5f5f5 0% 0% no-repeat padding-box;
        border-radius: 10px;
        transition: all 0.3s;

        .categories-img {
          .img-hover {
            display: none;
          }
          .img-clean {
            @media screen and (max-width: 1366px) {
              width: 35px;
            }
            @media screen and (max-width: 1024px) {
              width: 30px;
            }
            @media screen and (max-width: 768px) {
              width: 28px;
            }
          }
          @media screen and (max-width: 768px) {
            padding-top: 5px;
          }
        }

        h5 {
          font-weight: bold;
          color: #6c6c6c;
          font-size: 20px;
          margin: 15px 0;
          @media screen and (max-width: 1680px) {
            font-size: 18px;
          }
          @media screen and (max-width: 1440px) {
            font-size: 16px;
          }
          @media screen and (max-width: 768px) {
            font-size: 14px;
            margin: 5px;
          }
        }
        p {
          display: none;

          @media screen and (max-width: 769px) {
            display: block;
            font-size: 12px;
          }
          @media screen and (max-width: 320px) {
            margin: 0;
          }
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }

        @media screen and (min-width: 769px) {
          &:hover {
            -webkit-box-shadow: -8px 7px 30px -13px rgba(0, 0, 0, 0.57);
            -moz-box-shadow: -8px 7px 30px -13px rgba(0, 0, 0, 0.57);
            box-shadow: -8px 7px 30px -13px rgba(0, 0, 0, 0.57);
            padding: 0 10px 10px 10px;
            transition: width 0.3s, height 0.4s;
            width: 280px;
            height: 260px;
            background: #a874b9 0% 0% no-repeat padding-box;

            .categories-img {
              animation: fadeIn 0.5s ease-in;
              .img-hover {
                display: block;

                @media screen and (max-width: 1366px) {
                  width: 35px;
                }
                @media screen and (max-width: 1024px) {
                  width: 30px;
                }
              }
              .img-clean {
                display: none;
              }
            }
            h5 {
              animation: fadeIn 0.5s ease-in;
              color: #ffffff;
              margin: 5px 0;
            }
            p {
              animation: fadeIn 0.7s ease-in;
              color: #ffffff;
              display: block;
              text-align: justify;
              font-size: 14px;
              padding: 0 10px;

              @media screen and (max-width: 1280px) {
                font-size: 12px;
              }
            }
            @media screen and (max-width: 1280px) {
              width: 240px;
              height: 220px;
            }
            @media screen and (max-width: 1024px) {
              padding: 10px;
              width: 200px;
              height: 220px;
            }
          }
        }
        @media screen and (max-width: 1680px) {
          width: 220px;
          height: 180px;
        }
        @media screen and (max-width: 1440px) {
          width: 190px;
          height: 150px;
        }
        @media screen and (max-width: 1024px) {
          width: 160px;
          height: 130px;
        }
        @media screen and (max-width: 768px) {
          width: 140px;
          height: 220px;
          padding: 10px;
          display: flex;
          justify-content: flex-start;
        }
        @media screen and (max-width: 650px) {
          width: 150px;
          height: 220px;
        }
        @media screen and (max-width: 375px) {
          width: 100%;
          height: 120px;
          padding: 8px 10px 40px 10px;
        }
      }
      @media screen and (max-width: 375px) {
        width: 100%;
        padding-bottom: 20px;
      }
    }

    @media screen and (max-width: 650px) {
      width: 90%;

      &:first-child {
        padding-bottom: 10px;

        @media screen and (max-width: 375px) {
          padding-bottom: 0;
        }
      }
    }
    @media screen and (max-width: 375px) {
      flex-direction: column;
      width: 85%;
    }
  }
  @media screen and (max-width: 1680px) {
    max-width: 1280px;
  }
  @media screen and (max-width: 1600px) {
    max-width: 1200px;
  }
  @media screen and (max-width: 1440px) {
    max-width: 1100px;
  }
  @media screen and (max-width: 1366px) {
    max-width: 1050px;
  }
  @media screen and (max-width: 1024px) {
    max-width: 850px;
    padding-top: 20px;
    height: 250px;
  }
  @media screen and (max-width: 768px) {
    height: 260px;
    max-width: 720px;
    padding-top: 0;
  }
  @media screen and (max-width: 650px) {
    flex-direction: column;
    align-items: center;
    height: 550px;
  }
  @media screen and (max-width: 375px) {
    padding: 30px 0;
    height: 750px;
  }
}
